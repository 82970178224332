body {
  background-image: url('../src/images/LoginBackground.jpg');
  background-attachment: fixed;
  background-size: cover;
}

code {
  padding-top: .2rem;
  padding-bottom: .2rem;
  padding-left: .4rem;
  padding-right: .4rem;
  background-color: #DEDEDE;
}